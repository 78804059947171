@font-face {
  font-family: "Inter Bold";
  src: url(./assets/fonts/Inter/Inter-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Inter Black";
  src: url(./assets/fonts/Inter/Inter-Black.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Inter ExtraBold";
  src: url(./assets/fonts/Inter/Inter-ExtraBold.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Inter ExtraLight";
  src: url(./assets/fonts/Inter/Inter-ExtraLight.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Inter Light";
  src: url(./assets/fonts/Inter/Inter-Light.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Inter Medium";
  src: url(./assets/fonts/Inter/Inter-Medium.ttf);
}

@font-face {
  font-family: "Inter Regular";
  src: url(./assets/fonts/Inter/Inter-Regular.ttf);
}

@font-face {
  font-family: "Inter SemiBold";
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf);
}

@font-face {
  font-family: "Inter Thin";
  src: url(./assets/fonts/Inter/Inter-Thin.ttf);
}

@font-face {
  font-family: "Lato Black";
  src: url(./assets/fonts/Lato/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato BlackItalic";
  src: url(./assets/fonts/Lato/Lato-BlackItalic.ttf);
}

@font-face {
  font-family: "Lato Bold";
  src: url(./assets/fonts/Lato/Lato-Bold.ttf);
}

@font-face {
  font-family: "Lato BoldItalic";
  src: url(./assets/fonts/Lato/Lato-BoldItalic.ttf);
}

@font-face {
  font-family: "Lato Italic";
  src: url(./assets/fonts/Lato/Lato-Italic.ttf);
}

@font-face {
  font-family: "Lato Light";
  src: url(./assets/fonts/Lato/Lato-Light.ttf);
}

@font-face {
  font-family: "Lato LightItalic";
  src: url(./assets/fonts/Lato/Lato-LightItalic.ttf);
}

@font-face {
  font-family: "Lato Regular";
  src: url(./assets/fonts/Lato/Lato-Regular.ttf);
}

@font-face {
  font-family: "Lato Thin";
  src: url(./assets/fonts/Lato/Lato-Thin.ttf);
}

@font-face {
  font-family: "Lato ThinItalic";
  src: url(./assets/fonts/Lato/Lato-ThinItalic.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active-row {
  background-color: #FFC0CB;
}
.flex_between{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.flex_center{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.striped{
 background-color: #f5f5f5;
}